@import './theme';

$spacing-1: 8px;

html,
body,
#root {
  display: flex;
  flex: 1;
}

@media (max-width: 1210px) {
  html,
  body,
  #root {
    height: 100%;
  }
}

body {
  margin: 0;
  font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #{map-get($colors, 'whiteText')} !important;
  background-color: #{map-get($colors, 'body')} !important;
}

div {
  line-height: 1.7em;
}

p {
  margin: 0;
  margin-bottom: 16px;
}

h1,
h1.MuiTypography-h1,
h2,
h2.MuiTypography-h2,
h3,
h3.MuiTypography-h3,
h4,
h4.MuiTypography-h4,
h5,
h5.MuiTypography-h5,
h6,
h6.MuiTypography-h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

h1,
h1.MuiTypography-h1 {
  font-size: 64px;
  line-height: 64px;
  color: #{map-get($colors, 'headings')};
}

@media (max-width: 850px) {
  h1,
  h1.MuiTypography-h1 {
    font-size: 20px;
    line-height: 24px;
  }
}

h2,
h2.MuiTypography-h2 {
  font-size: 32px;
  line-height: 40px;
  color: #{map-get($colors, 'headings')};
}

h3,
h3.MuiTypography-h3 {
  font-size: 18px;
  line-height: 20px;
  padding-bottom: $spacing-1;
  color: #{map-get($colors, 'headings')};
}

h4,
h4.MuiTypography-h4 {
  font-size: 14px;
  line-height: 16px;
  padding-bottom: $spacing-1;
  color: #{map-get($colors, 'headings')};
}

h5,
h5.MuiTypography-h5 {
  font-size: 12px;
  line-height: 14px;
  color: #{map-get($colors, 'subtleText')};
}

a {
  color: #{map-get($colors, 'link')};
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    color: #{map-get($colors, 'link')};
  }
}

ul {
  padding: 0 14px;
  margin: 0;
}

ul.mint {
  list-style: none; /* Remove default bullets */
}

ul.mint li::before {
  content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #{map-get($colors, 'mint')}; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  font-size: 16px;
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #{map-get($colors, 'scrollbarTrack')};
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #{map-get($colors, 'scrollbarThumb')};
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #{map-get($colors, 'scrollbarThumbHover')};
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
